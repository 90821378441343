import { useState } from "react";
import Head from "next/head";
import useUser from "../lib/useUser";
import { Layout } from "../common/Layout";
import { Imgix, Hero, Section } from "../common/UI";
import withSession from "../lib/session";
import { Container, Grid, Button, Box, Typography } from "@mui/material";

import { Carousel } from "react-responsive-carousel";

export default function Error404Page() {
  return (
    <Layout>
      <Grid item>
        <Hero minHeight={400}>
          <Box sx={{}}>
            <Typography
              variant="h2"
              sx={{
                textShadow: "0 1px 0 black",
                textAlign: "center",
              }}
            >
              No Waves Here, Sorry (404)
            </Typography>
          </Box>
          <Box sx={{}}>
            <Typography
              variant="h4"
              sx={{
                textShadow: "0 1px 0 black",
                textAlign: "center",
              }}
            >
              <a href="/">Back to Big Surf Adventures Homepage</a>
            </Typography>
          </Box>
        </Hero>
      </Grid>
    </Layout>
  );
}
